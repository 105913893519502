<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Create new lead"
    primary-button-text="Create lead"
    :disabled="loading"
    secondary-button-text="Cancel"
    @update:model-value="cancel"
    @confirm="submit"
    @cancel="cancel"
  >
    <form id="lead-form" class="h-full" @submit.prevent>
      <h5 class="text-subhead-1 mb-4 text-black-60">Lead contacts</h5>
      <UiInputPhone
        v-model="lead"
        name="Phone"
        label="Phone number *"
        :placeholder="!validatePhone() ? 'Add number' : 'Number'"
        class="mb-8"
        :error="useGetFieldErrors(v$, ['phone_country_id', 'phone'])"
        @update:model-value="preselectCountry"
      />

      <h5 class="text-subhead-1 mb-4 text-black-60">Lead details</h5>
      <UiInputTextField
        v-model="lead.name"
        label="Lead name *"
        name="Name"
        placeholder="Lead name"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['name'])"
      />

      <UiInputSelect
        v-model="lead.country_id"
        :items="countryItems"
        label="Country *"
        name="Country"
        placeholder="Choose country"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['country_id'])"
      />

      <UiInputSelect
        v-model="lead.language_id"
        :items="languageItems"
        label="Language *"
        name="Language"
        placeholder="Choose language"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['language_id'])"
      />

      <UiInputSelect
        v-model="lead.timezone_id"
        :items="timezoneItems"
        align-right
        label="Timezone"
        name="Timezone"
        placeholder="Select a timezone"
        class="mb-4"
      />

      <UiInputSelect
        v-model="lead.lead_source_id"
        :items="sourceItems"
        label="Source *"
        name="Source"
        placeholder="Type or select from the list"
        class="mb-4"
        add-new
        :error="useGetFieldErrors(v$, ['lead_source_id'])"
        @update:model-value="addCustomValue($event)"
        @updated:shallow-value="getSources($event)"
      />

      <UiInputTextField
        :model-value="lead.reporter?.name || user?.name"
        name="CreatedBy"
        label="Lead created by"
        disabled
        class="mb-8"
      />
      <div v-if="!authStore.getIsAgent">
        <h5 class="text-subhead-1 mb-4 text-black-60">Place lead into</h5>
        <UiInputRadio id="choose_method" v-model="lead.save_strategy" :items="strategiesItems" class="mb-4" vertical />
        <UiInputSelect
          v-if="isAgentStrategy"
          v-model="lead.assignee_id"
          :items="userItems"
          name="Agents"
          group
          avatar
          placeholder="Choose a user"
          :error="useGetFieldErrors(v$, ['assignee_id'])"
        />
      </div>
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '~/store/auth'
import type { InputItem, Lead, Pipeline } from '@/types'
import { PIPELINES, STAGES, LEAD_STRATEGIES } from '@/constants'

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'created', lead: Lead): void
  (e: 'duplicate', lead: Lead): void
  (e: 'suggest', lead: Lead, duplicateId: string): void
}>()

const authStore = useAuthStore()

type Props = {
  modelValue: boolean
}
defineProps<Props>()

const user = useUser()
const poolPipeline = ref<Pipeline>()
const salesPipeline = ref<Pipeline>()

const lead = ref<Partial<Lead>>({
  name: '',
  pipeline_stage_id: undefined,
  pipeline_id: undefined,
  phone: null,
  phone_country_id: null,
  country_id: null,
  language_id: null,
  timezone_id: null,
  lead_source_id: null,
  assignee_id: undefined,
  save_strategy: undefined,
})

const loading = ref(false)
const countryItems = ref<InputItem[]>([])
const languageItems = ref<InputItem[]>([])
const userItems = ref<InputItem[]>([])
const timezoneItems = ref<InputItem[]>([])
const sourceItems = ref<InputItem[]>([])
const strategiesItems = ref<InputItem[]>([])

const activeStrategy = computed(() => {
  return strategiesItems.value.find((s) => s.value === lead.value.save_strategy)?.code
})

const isAgentStrategy = computed(() => {
  return activeStrategy.value === LEAD_STRATEGIES.AGENT
})

const validatePhone = () => {
  return useValidatePhone(+lead.value.phone, lead.value.phone_country_id)
}

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  pipeline_stage_id: {
    // If the user selects an assignee when creating a lead, a stage id required
    required: helpers.withMessage('Pipeline is required', requiredIf(isAgentStrategy.value)),
  },
  phone: {
    required: helpers.withMessage('Phone is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  phone_country_id: {
    required: helpers.withMessage('Phone country is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  country_id: { required: helpers.withMessage('Country is required', required) },
  language_id: { required: helpers.withMessage('Language is required', required) },
  lead_source_id: { required: helpers.withMessage('Source is required', required) },
  assignee_id: {
    requiredIfValue: helpers.withMessage(
      'Assignee is required',
      requiredIf(authStore.getIsAgent || isAgentStrategy.value)
    ),
  },
}))

const v$ = useVuelidate(rules, lead)

const preselectCountry = () => {
  if (!lead.value.country_id) {
    lead.value.country_id = lead.value.phone_country_id
  }
}

const getCountries = async () => {
  countryItems.value = await useGetCountriesItems()
}

const getLanguages = async () => {
  languageItems.value = await useGetLanguageItems()
}

const getTimezones = async () => {
  const data = await useTimezones()
  timezoneItems.value = data.map((t) => ({ text: `${t.offset} ${t.name}`, value: t.id, ...t }))
}

const getSources = async (query?: string) => {
  sourceItems.value = await useGetLeadSourcesItems(query)
}

const addCustomValue = async (value: string) => {
  try {
    if (!sourceItems.value.length) {
      const { data } = await useAddLeadSource(value)
      sourceItems.value.unshift({ text: data.name, value: data.id })
      lead.value.lead_source_id = data.id
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getAgents = async () => {
  userItems.value = await useGetUsersItems(true)
}

const getStrategies = async () => {
  const strategies = await useCreationLeadStrategies()
  strategiesItems.value = strategies.map((s) => ({ text: s.name, value: s.id, code: s.code }))
  lead.value.save_strategy = strategiesItems.value.find((s) => s.code === LEAD_STRATEGIES.AGENT)?.value
}

const getPipelines = async () => {
  try {
    const [pool, sales] = await Promise.all([
      useGetPipelineByCode(PIPELINES.POOL),
      useGetPipelineByCode(PIPELINES.SALES),
    ])
    poolPipeline.value = pool
    salesPipeline.value = sales
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

onNuxtReady(
  async () =>
    await Promise.all([
      getCountries(),
      getLanguages(),
      getSources(),
      getAgents(),
      getPipelines(),
      getStrategies(),
      getTimezones(),
    ])
)

if (authStore.getIsAgent && user?.id) {
  lead.value.assignee_id = user?.id
}

const cancel = () => {
  lead.value = {
    name: '',
    pipeline_stage_id: undefined,
    phone: null,
    phone_country_id: null,
  }
  emits('update:modelValue', false)
}

const uiStore = useUiStore()

const DUPLICATE_ERROR_KEYWORD = 'duplicate'
const DUPLICATE_IN_CLOSE_ERROR_KEYWORD = 'close'

const { extractValueFromTag } = useTagFormatter()

const createLead = async (lead: Partial<Lead>) => {
  const { data, error } = await useCreateLead(lead)

  if (data.value?.data) {
    emits('created', data.value?.data)
  }

  if (error.value) {
    const isDuplicateError = error.value.message.includes(DUPLICATE_ERROR_KEYWORD)
    const isDuplicateInClose = error.value.data.errors?.name.some((error) =>
      error.includes(DUPLICATE_IN_CLOSE_ERROR_KEYWORD)
    )

    if (isDuplicateInClose) {
      const duplicateId = extractValueFromTag({
        text: error.value.message,
        tag: 'span',
      })

      emits('suggest', lead as Lead, duplicateId)

      return
    }

    if (isDuplicateError) {
      emits('duplicate', lead as Lead)
    }
  }
}

const submit = async () => {
  if (isAgentStrategy.value || activeStrategy.value === LEAD_STRATEGIES.ROTATION) {
    lead.value.pipeline_id = salesPipeline.value?.id
    lead.value.pipeline_stage_id = salesPipeline.value?.stages.find((s) => s.code === STAGES.NEW)?.id
  } else {
    lead.value.pipeline_id = poolPipeline.value?.id
  }

  const isValid = await v$.value.$validate()
  if (!isValid) return

  try {
    loading.value = true

    const localLead = omitBy(toRaw({ ...lead.value, phone: lead.value.phone?.toString() }), (f) => !f || f === 'hidden')

    await createLead(localLead)

    emits('update:modelValue', false)
  } catch (e) {
    uiStore.showSnackBanner('', 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
